import {isEmpty} from 'lodash';
const loginModule = angular.module('alm.login.application');

loginModule.factory('urlUtilsService', function ($location, session) {
	'ngInject';
	const baseUrl = '..';
	const baseUiUrl = '/ui';
	const projectInfoParamString = 'p=';
	const forceAuthenticateParamString = 'fa=';
	const baseAuthUrl = baseUrl + '/authentication/sign_in';
	const baseAuthCheckUrl = baseUrl + '/rest/is-authenticated';
	let redirectTarget = '';

	(function buildRedirectTarget() {
		redirectTarget = session.redirectTarget;
		const query = $location.url();
		if (!isEmpty(query)) {
			redirectTarget = redirectTarget + '#' + query;
		}
	})();

	return {
		getBaseUrl: function () {
			return baseUrl;
		},
		getRedirectTarget: function () {
			return redirectTarget;
		},

		getBaseAuthCheckUrl: function () {
			return baseAuthCheckUrl;
		},

		getBaseAuthUrl: function () {
			return baseAuthUrl;
		},

		getBaseAppUrl: function () {
			return baseUrl + baseUiUrl;
		},

		hasRedirectInfo: function () {
			return redirectTarget.indexOf(projectInfoParamString) > -1;
		},
		forceAuthenticate: function forceAuthenticate() {
			// extracting 'fa' (forceAuthenticate) parameter from URL
			if (redirectTarget.indexOf(forceAuthenticateParamString) > -1) {
				const faParamLength =
					redirectTarget.indexOf(forceAuthenticateParamString) +
					forceAuthenticateParamString.length;
				const fa = redirectTarget.substring(faParamLength);

				// we remove the 'fa' parameter from URl for further redirection
				redirectTarget = redirectTarget.substring(
					0,
					redirectTarget.length - forceAuthenticateParamString.length - fa.length - 1
				);
				return fa === 'true';
			}
			return false;
		},
	};
});
