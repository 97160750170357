import '../logger-module';

(function () {
	'use strict';

	var module = angular.module('platform-logger');

	module.config([
		'$provide',
		function ($provide) {
			$provide.decorator('$log', [
				'$delegate',
				'loggerDecoration',
				function ($delegate, loggerDecoration) {
					var myLogger = loggerDecoration($delegate);
					return myLogger;
				},
			]);
		},
	]);

	module.factory('loggerDecoration', function ($window) {
		var myWindow, openConsoleWindow;

		(function initLog() {
			var paramString = $window.location.search.indexOf('consoleLog');
			if (paramString !== -1 && !openConsoleWindow) {
				myWindow = $window.open(
					'',
					'Error log',
					'width=650,height=650,top=0,left=0,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,location=no,status=no'
				);
				myWindow.opener.focus();
				myWindow.document.write('<title>Console Log</title>');
				openConsoleWindow = true;
			}
		})();

		function printLog(logName, args) {
			var strArg = logName + ': ';
			angular.forEach(args, function (arg) {
				strArg += ' ';
				if (typeof arg === 'string') {
					strArg += _.escape(arg);
				} else {
					strArg += _.escape(angular.toJson(arg));
				}
			});
			if (myWindow) {
				myWindow.document.write('<div>' + strArg + '</div>\n');
				angular.element(myWindow.document).scrollTop(angular.element(myWindow.document).height());
			}
		}

		function shadowLogger($delegate) {
			var decoratedLog = {
				log: function () {
					$delegate.log.apply($delegate, arguments);
					printLog('<span style="color: #008000">LOG</span>', arguments);
				},
				info: function () {
					$delegate.info.apply($delegate, arguments);
					printLog('<span style="color: blue">INFO</span>', arguments);
				},
				error: function () {
					$delegate.error.apply($delegate, arguments);
					printLog('<span style="color: #ff0000">ERROR</span>', arguments);
				},
				warn: function () {
					$delegate.warn.apply($delegate, arguments);
					printLog('<span style="color: #ff8c00">WARN</span>', arguments);
				},
				debug: function () {
					$delegate.debug.apply($delegate, arguments);
					printLog('<span style="color: #777777">DEBUG</span>', arguments);
				},
			};

			return openConsoleWindow ? decoratedLog : $delegate;
		}

		return shadowLogger;
	});
})();
