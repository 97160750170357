import {IRootScopeService} from 'angular';
import {debounce} from 'lodash';

let digestIfNeeded: () => void = () => {};

let hasOutstandingRequests = false;
const noOutstandingAsyncRequestsCallbacks = new Set<() => void>();

interface AwaitInitConfiguration {
	$rootScope: IRootScopeService;
	inUnitTests: boolean;
	digestWait: number;
	digestMaxWait: number;
}

export function initAwaitAdapter({
	$rootScope,
	inUnitTests,
	digestWait,
	digestMaxWait,
}: AwaitInitConfiguration): void {
	function actualDigest() {
		try {
			$rootScope.$applyAsync();
		} finally {
			hasOutstandingRequests = false;

			for (let callback of noOutstandingAsyncRequestsCallbacks) {
				self.setTimeout(callback);
			}

			noOutstandingAsyncRequestsCallbacks.clear();
		}
	}

	let debounceDigest: () => void;

	if (inUnitTests) {
		debounceDigest = () => $rootScope.$apply();
	} else if (digestWait === 0) {
		debounceDigest = () => $rootScope.$applyAsync();
	} else {
		debounceDigest = debounce(actualDigest, digestWait, {
			maxWait: digestMaxWait,
			leading: true,
			trailing: true,
		});
	}

	digestIfNeeded = () => {
		if ($rootScope.$$phase === null) {
			hasOutstandingRequests = true;
			debounceDigest();
		}
	};
}

/**
 * An adapter used by babel-plugin-angularjs-digest-await
 * in order to hook async & await calls during compilation into angular digest cycle.
 *
 * Note that this adapter is also used in shared worker which has no access to DOM or angular
 * this is why we don't access angular services directly,
 * but depend on been initialized with angular services and other type of configuration
 *
 * Until adapter is initialized it acts as pass threw logic doing nothing besides delegating value it receives from await call
 * @param v
 */
export function awaitAdapter<T>(v: T): T {
	digestIfNeeded();

	return v;
}

self['$$await'] = awaitAdapter;

self[
	'octaneNotifyWhenNoOutstandingAsyncRequests'
] = function octaneNotifyWhenNoOutstandingAsyncRequests(callback: () => void) {
	if (!hasOutstandingRequests) {
		callback();
		return;
	}

	noOutstandingAsyncRequestsCallbacks.add(callback);
};
