/**
 * @ngdoc overview
 * @name platform-logger
 * @description
 *
 * logger module, containing the logger service.
 */
(function () {
	'use strict';

	angular.module('platform-logger', ['platform-session']);
})();
