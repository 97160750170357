import {StackFrame} from 'stacktrace-js';
import {session} from '../../session/services/session';
import {Logger} from 'platform/logger/services/logger';
import {platformErrorModule} from '../error-module';

const stackTraceJS = _.memoize(() =>
	import(/*webpackChunkName: "stacktrace-js" */ 'stacktrace-js')
);

/**
 * Helper for exception utility functions
 */
export class ExceptionHelper {
	private logger = new Logger('ExceptionHelper');

	private stackCache = new Map<string, Promise<string>>();

	/**
	 * hold reference and que format operations to prevent overload of resources on the system
	 */
	private lastFormatStacktraceOperation: Promise<any> = Promise.resolve();

	/**
	 * unminnify stack trace of error
	 */
	public async formatStackTrace(error: Error): Promise<string> {
		if (session.siteParams.IMPROVE_JS_EXCEPTION !== 'true' || _.isEmpty(error.stack)) {
			return error.stack;
		}

		try {
			let cachedStack = this.stackCache.get(error.stack);
			if (cachedStack) {
				return await cachedStack;
			}

			try {
				await this.lastFormatStacktraceOperation;
			} catch (e) {
				//ignore error
			}

			this.logger.debug(
				'Starting translation of error stack trace to unminified form for error',
				error
			);

			const {fromError} = await stackTraceJS();

			cachedStack = fromError(error).then((stackFrames: StackFrame[]) => {
				const newStack = stackFrames.reduce(
					(result, stackFrame) => result + this.formatStackFrame(stackFrame),
					error.toString()
				);

				return newStack;
			});

			this.lastFormatStacktraceOperation = cachedStack;

			this.stackCache.set(error.stack, cachedStack);

			return await cachedStack;
		} catch (e) {
			this.logger.error('Failed to resolve stack trace', error, e);
			return error.stack;
		}
	}

	private formatStackFrame(frame: StackFrame) {
		const functionName = frame.functionName;
		const lineNumber = frame.lineNumber;
		const fileName = _(frame.fileName || '')
			.split('/')
			.last();

		return `\n    at ${functionName} (${fileName}:${lineNumber})`;
	}

	public cloneError(error: Error): Error {
		const clone = new (Object.getPrototypeOf(error).constructor)(error.message);
		clone.stack = error.stack;

		return clone;
	}

	/**
	 * Does current error represent error fully originating in 3rd party code
	 */
	public isThirdPartyError(error: Error): boolean {
		const fileExpression = /at [^(]*\(?(http[^)]*)\)?/gim;

		const stack = error.stack || '';

		const vendropExp = /vendor\.dll/;

		for (const match of stack.matchAll(fileExpression)) {
			if (!vendropExp.test(match[1])) {
				return false;
			}
		}

		return true;
	}
}

platformErrorModule.service('exceptionHelper', ExceptionHelper);

export let exceptionHelper: ExceptionHelper;

platformErrorModule.run(function ($injector: angular.auto.IInjectorService) {
	exceptionHelper = $injector.get('exceptionHelper');
});
