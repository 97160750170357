/**
 * @ngdoc overview
 * @name platform-session
 * @description
 *
 * Platform session module. Mainly provides services and information regarding current alm session.
 */
(function () {
	'use strict';

	angular.module('platform-session', []);
	angular.module('platform-session-setup', []);
})();
