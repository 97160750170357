import {IRootScopeService} from 'angular';
import {Session} from './session';
import {awaitAdapter, initAwaitAdapter} from 'platform/session/services/await-adapter';

angular.module('platform-session').run(bridgeBetweenNativePromiseAndAngularDigestCycle);

function bridgeBetweenNativePromiseAndAngularDigestCycle(
	$rootScope: IRootScopeService,
	session: Session
) {
	'ngInject';
	const originalPromise = window.Promise;
	const originalThen = originalPromise.prototype.then;

	initAwaitAdapter({
		$rootScope,
		inUnitTests: session.inUnitTests,
		digestWait: Number(session.siteParams.DIGEST_DEBOUNCE),
		digestMaxWait: Number(session.siteParams.DIGEST_MAX_DEBOUNCE),
	});

	patchNativePromiseWithInterceptor();

	return;

	/**
	 * Intercept native promise to trigger angular digest cycle
	 */
	function patchNativePromiseWithInterceptor() {
		originalPromise.prototype.then = function octanePromiseThenInterceptor() {
			const promise = originalThen.apply(this, arguments);

			originalThen.call(promise, awaitAdapter, awaitAdapter);

			return promise;
		};

		$rootScope.$on('$destroy', () => {
			originalPromise.prototype.then = originalThen;
		});
	}
}
