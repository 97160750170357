import '../../ui/platform/session/services/session';

const loginModule = angular.module('alm.login.application');

loginModule.factory('connectionService', function (session) {
	'ngInject';
	return {
		isProduction: function () {
			return session.isProduction;
		},
	};
});
