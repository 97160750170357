import '../session-module';
import {session} from 'platform/session/services/session';
import angular, {IWindowService} from 'angular';

const TEST_AUTOMATION_MODE_PARAM_NAME = 'TEST_AUTOMATION_MODE';

const TEST_AUTOMATION_ENABLED_BY_SITE_PARAMETER =
	session.siteParams[TEST_AUTOMATION_MODE_PARAM_NAME] === 'true';
export let testAutomationMode: boolean =
	TEST_AUTOMATION_ENABLED_BY_SITE_PARAMETER || detectRunningInsideTestAutomation();

if (TEST_AUTOMATION_ENABLED_BY_SITE_PARAMETER && session.isDevNotMinimized) {
	angular.module('platform-session-setup').run(function (Logger) {
		const logger = new Logger('session');

		logger.warn('*'.repeat(20));
		logger.warn('Running in TEST AUTOMATION MODE');
		logger.warn(`Use ${TEST_AUTOMATION_MODE_PARAM_NAME} site parameter to turn it off`);
		logger.warn('*'.repeat(20));
	});
}

if (testAutomationMode) {
	window['octaneWindowUID'] = (Math.random() + 1).toString(16).substring(7);
}

/**
 * Detect if we are in test automation mode
 */
function detectRunningInsideTestAutomation(): boolean {
	// selenium injects helpers into window with prefix of `cdc_`
	const hasSeleniumHelpers = !!Object.keys(window).find((key) => key.startsWith('cdc_'));
	return hasSeleniumHelpers;
}

interface MarkTaskEnd {
	(): void;
}

/**
 * Helper class to be used for integration with UI Automation tests
 */
class UiAutomationHelper {
	constructor(private $browser: IWindowService) {
		'ngInject';
	}

	/**
	 * Mark beginning of task that UI automation should wait for
	 * @param taskName
	 * @return callback to mark when task has finished
	 */
	markTaskStart(taskName: string): MarkTaskEnd {
		if (!testAutomationMode) {
			return angular.noop;
		}

		this.$browser.$$incOutstandingRequestCount(taskName);

		return () => {
			this.$browser.$$completeOutstandingRequest(angular.noop, taskName);
		};
	}

	writeToConsoleForUIAutomationReport(message: string): void {
		// we are using console because this is what UI automation collects at end of test to write to report,
		// we don't want to use logger for this
		// eslint-disable-next-line angular/log,no-console
		console.error(message);
	}
}

angular.module('platform-session').service('uiAutomationHelper', UiAutomationHelper);

export let uiAutomationHelper: UiAutomationHelper;

angular.module('platform-session').run(function ($injector: angular.auto.IInjectorService) {
	uiAutomationHelper = $injector.get('uiAutomationHelper');
});
