import {Session} from 'platform/session/services/session';
import {RestResource, RestResourceFactory} from 'platform/services/rest-resource';
import {StateService} from 'platform/states/services/state-service';
import {ILogService, IRootScopeService, IWindowService} from 'angular';
import '../logger-module';
import {exceptionHelper} from '../../error/services/exception-helper';
import _ from 'lodash';

const LOG_LEVEL_ERROR = 1;
const LOG_LEVEL_WARN = 2;
const LOG_LEVEL_INFO = 3;
const LOG_LEVEL_DEBUG = 4;

type TrackedLogEntry = any[];

export interface LoggerConstructor {
	new (loggerName: string): LoggerInstance;

	showDebug(loggers: string | string[]);
}

interface LoggersMap {
	[loggerName: string]: boolean;
}

/**
 * @ngdoc object
 * @name platform-logger.Logger
 * @constructor
 *
 * @description
 * A constructor function which allows creation of an individual logger for a specific component.
 * For explanation of what and when we should log, and a usage example look bellow at the example.
 *
 * <p>
 * Each of the logging methods (log, info, warn and error) accepts as input variable number of parameters, each can be either a string or
 * an object with a toString representation that makes sense in the context of a log
 * </p>
 *
 * @param {string} loggerName the name the logger to be used for output to log
 *
 * @example
 * <pre>
 *
 * import {LoggerConstructor} from 'platform/logger/services/logger';
 *
 * angular.module('foo-bar').factory( 'fooBarService', function ($q, $http, Logger: LoggerConstructor) {
 *
 *       //Create named logger to be used for logging
 *       //logger name should be same as the fully qualified component name which uses it
 *       //logger name will be added before each log message
 *       var logger = new Logger('fooBarService');
 *
 *       var obj = { prop1: 'val1', prop2: 'val2'};
 *
 *       //Log basic debug message to log
 *       logger.log('This is basic debug message ','with some info',obj);
 *       //This will output:
 *       //alm.platform.services.connection-service : This is basic debug message with some info { prop1: 'val1', prop2: 'val2'};
 *
 *       //Log info message one level higher that log which is basic debug message, here we would write some intresting information
 *       logger.info('this is an info message');
 *
 *       //Log warning message, warnings are things that aren't ok and should not happen but some thing the application can survive
 *       logger.warn('this is a warning message');
 *
 *       $http.get(url, configuration).
 *         success(function (data) {
 *             deferred.resolve(data);
 *         }).
 *         error(function (data) {
 *             //Ohh s#!^ we can't fetch list of domains, better log an error
 *             //error is some thing we can't easily recover from
 *             logger.error('Failed to fetch domain list', data);
 *             deferred.reject(data);
 *         });
 *     }
 * </pre>
 */

export class LoggerInstance {
	private originalName: string;
	private loggerName: string;
	private doNotSendLogsToServer: boolean;

	constructor(
		private $window: IWindowService,
		private $log: ILogService,
		private $rootScope: IRootScopeService,
		private session: Session,
		private logLevelNum: number,
		private debugLoggerNames: () => LoggersMap,
		loggerName: string,
		private logsResource: () => RestResource,
		private currentState: () => string,
		private trackLog: (params: any[]) => void,
		private trackedLog: TrackedLogEntry[]
	) {
		this.originalName = loggerName;
		this.loggerName = '(' + loggerName + ')';
		this.doNotSendLogsToServer =
			!session.siteParams || String(session.siteParams['SEND_UI_LOGS_TO_SERVER']) === 'false';
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#log
	 * @methodOf platform-logger.Logger
	 * @description Write a log message, eq to log4j.Level.DEBUG / log4j.Level.TRACE.
	 * <p>
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 * </p>
	 */
	log(...args) {
		this.invokeLogger(this.$log.log, args, LOG_LEVEL_DEBUG);
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#warn
	 * @methodOf platform-logger.Logger
	 * @description Write a warning message, eq to log4j.Level.WARN
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 */
	warn(...args) {
		this.invokeLogger(this.$log.warn, args, LOG_LEVEL_WARN);
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#info
	 * @methodOf platform-logger.Logger
	 * @description Write an error message, eq to log4j.Level.FATAL / log4j.Level.ERROR
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 */
	info(...args) {
		this.invokeLogger(this.$log.info, args, LOG_LEVEL_INFO);
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#error
	 * @methodOf platform-logger.Logger
	 * @description Write a log message, eq to log4j.Level.DEBUG / log4j.Level.TRACE
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 */
	async error(...args) {
		this.reportLogToServer('error', args);
		this.invokeLogger(this.$log.error, args, LOG_LEVEL_ERROR);
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#reportErrorWithCorrelationId
	 * @methodOf platform-logger.Logger
	 * @description Write a log message, eq to log4j.Level.DEBUG / log4j.Level.TRACE
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 * @param {String} correlationId id with which to report this issue to server
	 */
	reportErrorWithCorrelationId(correlationId: string, error, ...args): void {
		this.$rootScope.$applyAsync(async () => {
			const mergedArgs = [error, ...args];
			this.reportLogToServer('error', mergedArgs, correlationId);
			this.invokeLogger(this.$log.error, mergedArgs, LOG_LEVEL_ERROR);
		});
	}

	private async buildResolvedError(error: Error) {
		let errorClone = error;

		try {
			errorClone = exceptionHelper.cloneError(error);
			errorClone.stack = await exceptionHelper.formatStackTrace(error);
		} catch (e) {
			const mergedArgs = ['failed to format stack trace', e];
			this.reportLogToServer('error', mergedArgs);
			this.invokeLogger(this.$log.error, mergedArgs, LOG_LEVEL_ERROR);
			//if we failed, fall back to original error
			errorClone = error;
		}

		return errorClone;
	}

	/**
	 * @ngdoc method
	 * @name platform-logger.Logger#debug
	 * @methodOf platform-logger.Logger
	 * @description Write a log message, eq to log4j.Level.DEBUG / log4j.Level.TRACE
	 * As input accepts variable number of parameters, each can be ether a string or
	 * an object with a toString representation that makes sense in the context of a log
	 */
	debug(...args) {
		if (this.debugLoggerNames()[this.originalName]) {
			this.invokeLogger(this.$log.log, args, LOG_LEVEL_DEBUG);
		}
	}

	public static getRandomCorrelationId(): string {
		return new Date().getTime().toString();
	}

	private invokeLogger(loggingMethod: ng.ILogCall, originalArgs: any[], logLevelNum: number) {
		this.trackLog(originalArgs);

		if (logLevelNum > this.logLevelNum) {
			return;
		}

		const loggerName = this.loggerName;
		let args;

		if (this.session.isProduction) {
			args = [`${loggerName}:${_.map(originalArgs, mapArgumentToString).join(' ')}`];
		} else {
			args = [`${loggerName}:`, ...originalArgs];
		}

		loggingMethod.apply(this.$log, args);

		function mapArgumentToString(arg) {
			if (_.isError(arg)) {
				return arg.stack || arg.message;
			}

			if (_.isString(arg)) {
				return arg;
			} else {
				try {
					return angular.toJson(arg);
				} catch (e) {
					return `Failed to convert argument to JSON: ${e?.message}`;
				}
			}
		}
	}

	private reportLogToServer = _.debounce(
		async (
			logLevel: string,
			args: any[],
			correlationId = LoggerInstance.getRandomCorrelationId()
		) => {
			if (this.session.inUnitTests) {
				return;
			}

			if (this.doNotSendLogsToServer) {
				return;
			}

			try {
				const message = {
					loggerName: this.originalName,
					state: _.kebabCase(this.currentState()),
					details: this.prepareDetails(args),
					stack: _.head(_.compact(_.map(args, 'stack'))),
					logTail: this.prepareLogTail(),
				};

				if (_.isEmpty(message.stack)) {
					try {
						//noinspection ExceptionCaughtLocallyJS
						throw new Error('StackAtReportingError');
					} catch (err) {
						message.stack = err.stack;
					}
				}

				const dummyError = new Error();
				dummyError.stack = message.stack;
				const resolvedError = await this.buildResolvedError(dummyError);
				message.stack = resolvedError.stack;

				this.logsResource().add({
					logLevel,
					message,
					correlationId,
				});
			} catch (error) {
				//not using logger itself or letting error fall threw to avoid circular flow that will end up in same location
				this.$window.console.error('failed to write error to server', error);
			}
		},
		Number(this.session.siteParams.UI_REPORT_ERROR_TO_SERVER_INTERVAL) * 1000,
		{leading: true, trailing: false}
	);

	private prepareDetails(args: any[]) {
		return _.map(args, (value) => {
			try {
				if (_.isError(value)) {
					return value.message;
				} else if (_.isObject(value)) {
					return JSON.stringify(value);
				} else {
					return String(value);
				}
			} catch (err) {
				return '[[conversion failed]]';
			}
		});
	}

	private prepareLogTail() {
		return this.trackedLog.map((logEntry) => {
			const processedEntry = logEntry.map((part) => {
				try {
					//note that complex objects like DOM or Functions will be converted to empty objects
					return _.cloneDeep(part);
				} catch (e) {
					return '[[conversion failed]]';
				}
			});

			return processedEntry.length === 1 ? processedEntry[0] : processedEntry;
		});
	}
}

angular
	.module('platform-logger')
	.factory('Logger', function (
		$window: IWindowService,
		$log: ILogService,
		$injector: angular.auto.IInjectorService,
		$rootScope: IRootScopeService,
		session: Session
	) {
		let logsResource: RestResource;
		let stateService: StateService;

		const trackLogSize = Number(session.siteParams['WEB_UI_TRACK_LOG_SIZE']);
		const trackLogEnabled = trackLogSize > 0;
		const trackedLog: TrackedLogEntry = [];

		let debugLoggerNames: LoggersMap = {};

		let logLevelNumber = LOG_LEVEL_DEBUG;

		initLogLevelNumber();

		function LoggerBuilder(loggerName: string) {
			const trackMethod = trackLogEnabled ? trackLog : angular.noop;
			//cheating here to create interface which is like constructor but in fact a factory method
			return new LoggerInstance(
				$window,
				$log,
				$rootScope,
				session,
				logLevelNumber,
				getDebugLoggers,
				_.kebabCase(loggerName),
				getLogsResource,
				currentState,
				trackMethod,
				trackedLog
			);
		}

		LoggerBuilder['showDebug'] = function showDebug(newLoggerNames) {
			if (angular.isArray(newLoggerNames)) {
				var newLoggerNamesMap: LoggersMap = {};
				angular.forEach(newLoggerNames, function (name) {
					newLoggerNamesMap[name] = true;
				});
				debugLoggerNames = newLoggerNamesMap;
				$log.log('Current debugged loggers: ', debugLoggerNames);
			} else {
				debugLoggerNames[newLoggerNames] = true;
				$log.log('Current debugged loggers: ', debugLoggerNames);
			}
		};

		return LoggerBuilder;

		function getDebugLoggers(): LoggersMap {
			return debugLoggerNames;
		}

		function initLogLevelNumber() {
			if (session.logLevel) {
				var logLevel = session.logLevel;
				switch (logLevel) {
					case 'warn':
						logLevelNumber = LOG_LEVEL_WARN;
						break;
					case 'info':
						logLevelNumber = LOG_LEVEL_INFO;
						break;
					case 'debug':
						logLevelNumber = LOG_LEVEL_DEBUG;
						break;
					default:
						logLevelNumber = LOG_LEVEL_ERROR;
				}
			}
		}

		function currentState(): string {
			if (!stateService) {
				//we defer creation to bypass circular reference
				stateService = $injector.get('stateService') as StateService;
			}

			return stateService.getCurrentState(false).name;
		}

		function getLogsResource(): RestResource {
			if (!logsResource) {
				//we defer creation to bypass circular reference
				var restResource = $injector.get('restResource') as RestResourceFactory;

				var options;

				if (session.isSiteAdminContext) {
					options = {
						resourceLevel: 'admin',
					};
				}

				logsResource = restResource('ui_logs', null, options);
			}

			return logsResource;
		}

		function trackLog(params: any[]) {
			trackedLog.unshift(params);
			trackedLog.splice(trackLogSize + 1);
		}
	});

export let Logger: LoggerConstructor;

angular.module('platform-logger').run(function ($injector: angular.auto.IInjectorService) {
	Logger = $injector.get('Logger');
});
