/**
 * @ngdoc overview
 * @name platform-translation
 * @description
 *
 * translation module, containing the translation service and directive.
 */
(function () {
	'use strict';

	angular.module('platform-translation', ['platform-logger', 'platform-session']);
})();
