/**
 * @ngdoc directive
 * @name platform-translation.directive:translate
 * @restrict AE
 * @description
 * The directive should be used in places where the english text specified in the HTML should be translated to other
 * languages in case the ALM server is not english.<p>
 * The directive can be a separate element or an attribute.<p>
 * In case the directive is an attribute, the attribute should hold the key of the message, while the text inside the
 * HTML element will be used to display the english version of the message:
 * <pre>
 <span translate="qc:help">Help</span>
 <a href="" ng-click="onLogout()" translate="qc:logout">Logout from project {{projectName}}</a>
 * </pre>
 * In case the directive is the HTML element, the key should reside in the key attribute:
 * <pre>
 <translate key="user">User: {{user.name}}</translate>
 * </pre>
 * For non-english versions of the UI, the message displayed will be automatically fetched from the corresponding
 * resource file using the {@link platform-translation.translate translate} service, rather than the original english message.<p>
 * Note how in some of the above examples the namespace qc is used to prefix the key. Namespaces should be used to avoid
 * conflicts between various extensions, for example qc is used for quality center. For platform namespace shouldn't
 * be used. Register the namespace for your extension explicitly using the
 * {@link platform-translation.translateProvider#registerNamespace translateProvider}.
 * <p>Stick to the following message key naming convention to avoid conflicts: (namespace):(component)-(key-in-component)
 */

(function () {
	'use strict';

	angular.module('platform-translation').directive('translate', function (translate) {
		var directiveActive = !translate.isDefaultLanguage();

		return {
			restrict: directiveActive ? 'AE' : 'M', //Skip activation of directive all together for default language
			priority: 1000,
			compile: function (tElement, tAttrs) {
				var key = tAttrs.key || tAttrs.translate;

				var message = translate.getMessage(key);
				// Check if message is valid (not null). In case it's not, we want to display the original
				// english message.
				if (!message) {
					return;
				}

				if (tAttrs.placeholder) {
					tElement.attr('placeholder', message);
					tAttrs.placeholder = message;
				} else if (tAttrs['data-placeholder']) {
					tElement.attr('data-placeholder', message);
				} else if (tAttrs.translateAttribute) {
					tElement.attr(tAttrs.translateAttribute, message);
				} else {
					tElement.text(_.unescape(message));
				}
			},
		};
	});

	angular.module('platform-translation').directive('translate', function ($injector) {
		return {
			restrict: 'AE',
			link($scope) {
				//adding additional directive of translate in order to add entityLabels service to the scope.
				//The user  will be able to add entity/subtypes label in the string with the following
				// syntax(i.e.:<span translate="qc:critical-defects" >CRITICAL {{::entityLabels.defect.plural}}</span>
				$scope.entityLabels = $injector.has('entityLabels')
					? $injector.get('entityLabels')
					: undefined;
			},
		};
	});
})();
