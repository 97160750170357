import '../translation-module';
import {Session} from '../../session/services/session';
import {LoggerConstructor} from '../../logger/services/logger';
import {Dictionary} from 'lodash';

import {EntityLabels} from './entity-labels';

/**
 * @ngdoc object
 * @name platform-translation.translateProvider
 * @description
 *
 * The translateProvider enables the developer to configure the translate service and declare the messages from
 * within the code for further usage in controller, other services and other parts of the code.<p>
 * Each extension/application that provides its own localization resources and is interested to avoid collisions in
 * keys with other extensions should register itself with a unique namespace (usually very short prefix, like qc for
 * quality-center). Then every localization key referenced by this extension should be prefixed by this namespace
 * followed by colon, e.g. <span translate="qc:domain">
 */

/**
 * @ngdoc service
 * @name platform-translation.translate
 * @requires Logger
 * @requires $http
 * @requires $q
 * @requires $interpolate
 * @description
 *
 * The translate service enables the developer to translate text to the desired language. The language is supposed
 * to be configured by the ALM server, and not by the client machine or browser locale.
 * The service is most of the time used from within the javascript code to translate messages with optional
 * parameters. The service is itself a function. The usage is generally like this:
 * <pre>
 * module.controller(function(translate, sessionService) {
 *       var userName = sessionService.getUserName();
 *       var projectName = sessionService.getProjectName();
 *       var translatedMessage = translate('qc:user-logged-in-project', {project: projectName, user: userName});
 *       // Use the translatedMessage here
 *     });
 * </pre>
 * Note the key in this example is <pre>'qc:user-logged-in-project'</pre>The qc is a namespace that should have been
 * previously registered using the {@link platform-translation.translateProvider translateProvider}. Also, the
 * whole key should be mapped using the {@link platform-translation.translateProvider translateProvider} to
 * the message itself (see {@link platform-translation.translateProvider#declareMessages declareMessages()}).
 * The message itself can have angular-style parameters that will be interpolated during the translation,
 * meaning the values for these parameters will be taken from the parameters object sent to the service.
 * For example for the code above the message could be <pre>'The user {{user}} is logged in project {{project}}'</pre>.
 */

declare var i18n: any;
declare var almSession: Session;

const DEFAULT_LANGUAGE = 'english';

var resStore = {};
var successLogs = [];
var failureLogs = [];

function initI18N(language: string) {
	i18n.init({
		lng: language,
		ns: {namespaces: [], defaultNs: 'alm'},
		keyseparator: '###', // just to support keys with dots as a regular keys and not nesting levels
		debug: false,
		resStore: resStore,
		returnObjectTrees: true,
	});
}

angular.module('platform-translation').config(function (session: Session) {
	initI18N(session.language);
});

export type DeclaredMessage = string;
export type DeclaredMessages = Dictionary<DeclaredMessage>;
type StringLike = string | number;

/**
 * Combined translation configuration which merges both declaration and request for translation into one object.
 * for default language given values will be used, in other languages `text` will be pulled from translation files.
 * Keep in mind that this object is scanned and extracted using AST during build, it should be simple POJO.
 */
interface TranslateCombinedWithDeclaration {
	/**
	 * translation key
	 */
	key: string;
	/**
	 * translation text/message to be translated
	 */
	text: string;
	/**
	 * parameters to be injected into translated message
	 */
	parameters?: Dictionary<StringLike>;
}

export interface Translate {
	/**
	 * @deprecated instead should use the second form of translate, see details bellow.
	 *
	 * Translate message which was declared using declareMessages block
	 *
	 * @param translationKey key of declared message
	 * @param parameters parameters to be injected into translated message
	 */
	(translationKey: string, parameters?: Dictionary<StringLike>): string;

	/**
	 * Combined declaration and translation of message, see TranslateCombinedWithDeclaration
	 * @param translateCombinedWithDeclaration
	 */
	(translateCombinedWithDeclaration: TranslateCombinedWithDeclaration): string;

	isDefaultLanguage(): boolean;
	getMessage(translationKey: string): DeclaredMessage;
	resourcesLoaded(): boolean;
	getDeclaredMessages(): DeclaredMessages;
	/**
	 * @ngdoc method
	 * @name platform-translation.translate#overrideMessage
	 * @methodOf platform-translation.translate
	 *
	 * @description
	 * Override the value of the given translationKey with the newValue,
	 * should not be used, unless there is no better way to provide correct translation
	 */
	overrideMessage(translationKey: string, newValue: string);
}

interface ParamsSupplier {
	(): Dictionary<StringLike>;
}

interface MessageParamsInjector {
	/**
	 * @return translated message
	 */
	(paramsSupplier: ParamsSupplier): string;
}

export class TranslateProvider implements ng.IServiceProvider {
	private declaredMessages: DeclaredMessages = {};
	private acceptMessageDeclarations = true;
	private lastConfigurationError = null;

	/* @ngInject */
	constructor(private session: Session) {}

	/**
	 * @ngdoc method
	 * @name platform-translation.translateProvider#declareMessages
	 * @methodOf platform-translation.translateProvider
	 *
	 * @description
	 *
	 * <b><i>
	 *     Where possible should prefer to use translate service directly using the method call that combines
	 * 	   both declaration and translation in one call, instead of using declareMessages call.
	 * 	   See details in translation.md
	 * </i></b>
	 *
	 * Declare messages from withing the code. Use this function when configuring the application to declare new
	 * messages in english for further consumption by the message key using the translate service. This function is
	 * also used in the build process to automatically generate the translation resource file for the default
	 * english locale.<p>
	 * Calling this function from several times with different parameters is supported, in which case the messages
	 * will be merged.<p>
	 * For the platform code don't use any namespace. For another extension use namespaces previously registered using
	 * {@link platform-translation.translateProvider#registerNamespace registerNamespace()} for this extension.
	 * For example, here's how two messages are registered in the platform module:
	 * <pre>
	 * angular.module('platform').config(function(translateProvider) {
	 *       translateProvider.declareMessages({
	 *          'logout-from-project': 'Logout from project {{projectName}}',
	 *          'help': 'Help'
	 *        });
	 *      });
	 * </pre>
	 * And here's an example of how two messages are registered in the quality-center module:
	 * <pre>
	 * angular.module('quality-center').config(function(translateProvider) {
	 *       translateProvider.declareMessages({
	 *         'qc:user-is': 'The user is {{user.name}}',
	 *         'qc:help': 'Help'
	 *       });
	 *     });
	 * </pre>
	 * <p>Stick to the following message key naming convention to avoid conflicts: (namespace):(component)-(key-in-component)
	 * @param {Object} messages the object containing the messages, while the key will be the keys of the messages
	 * and the values will be the messages themselves.
	 */
	public declareMessages(messages: DeclaredMessages) {
		angular.forEach(messages, (value, key) => {
			if (this.declaredMessages.hasOwnProperty(key) && this.declaredMessages[key] !== value) {
				this.lastConfigurationError = new Error('Duplicate translation key declared, key=' + key);
			} else {
				this.declaredMessages[key] = value;
			}
		});
	}

	public isDefaultLanguage(): boolean {
		return DEFAULT_LANGUAGE === i18n.lng();
	}

	/**
	 * @ngdoc method
	 * @name platform-translation.translateProvider#registerNamespace
	 * @methodOf platform-translation.translateProvider
	 *
	 * @description
	 * Registers a new application with its unique namespace, consequently loading their translation
	 * resource file(s) during the application config. Then every localization key referenced by this extension
	 * should be prefixed by this namespace followed by colon, e.g. <span translate="qc:domain">.<p>
	 * No namespace should be used for the platform module.
	 * @param {String} namespace namespace
	 * @param {String} appName the application name
	 */
	public registerNamespace(namespace: string, appName: string) {
		var lng = i18n.lng();

		if (!resStore[lng]) {
			resStore[lng] = {};
		}

		if (resStore[lng][namespace]) {
			return;
		}

		var namespaceLoadSuccess = (response) => {
			successLogs.push('Loaded translation resource for language ' + lng + ' for ' + appName);
			resStore[lng][namespace] = response;
			i18n.options.ns.namespaces.push(namespace);
		};

		var namespaceLoadError = (_jqXHR, _textStatus, errorThrown) => {
			failureLogs.push(
				'Failed to load translation resource for language ' +
					lng +
					' for ' +
					appName +
					' with error:' +
					errorThrown
			);

			almSession.language = this.session.language = DEFAULT_LANGUAGE;

			initI18N(DEFAULT_LANGUAGE);

			this.registerNamespace(namespace, appName);
		};

		var path =
			'generated/' +
			appName +
			'-' +
			lng +
			'-translation.json?ui-build=' +
			almSession.currentServerVersion;

		this.fetchResource(path, namespaceLoadSuccess, namespaceLoadError);
	}

	private fetchResource(path, successCallback, errorCallback) {
		if (!this.session.inUnitTests && !this.isDefaultLanguage()) {
			/* global jQuery */
			jQuery.ajax({
				url: path,
				success: successCallback,
				error: errorCallback,
				dataType: 'json',
				async: false,
			});
		}
	}

	/* @ngInject */
	public $get = /* @ngInject*/ (
		$interpolate: ng.IInterpolateService,
		$injector: ng.auto.IInjectorService,
		session: Session,
		Logger: LoggerConstructor
	) => {
		let translateProvider = this;

		const messagesWithOverride: Dictionary<boolean> = {};

		const logger = new Logger('translation');

		if (this.lastConfigurationError) {
			logger.error(this.lastConfigurationError.message);
			throw this.lastConfigurationError;
		}

		const notProduction = !session.isProduction;

		let isResourceLoaded = false;

		const paramsInjectorCache = new Map<string, MessageParamsInjector>();

		logger.log('Language is ' + i18n.lng());

		function validateResult(key: string, result: string): boolean {
			if (key === result) {
				handleKeyNotFound('Failed to translate because ' + key + ' key was not found', true);
				return false;
			}

			return true;
		}

		function handleKeyNotFound(errorMessage: string, waitForResource: boolean) {
			if (
				notProduction &&
				(!waitForResource || isResourceLoaded) &&
				translateProvider.isDefaultLanguage()
			) {
				// If we're in dev environment, we want to fail early so dev will fix the problem
				throw new Error(errorMessage);
			}
		}

		function getMessageInDefaultLanguage(key): DeclaredMessage {
			var message = translateProvider.declaredMessages[key];
			if (message) {
				return message;
			}

			handleKeyNotFound('Failed to find declared english message by key ' + key, false);

			return '[' + key + ']';
		}

		let defaultParameters: {entityLabels: EntityLabels};

		const getDefaultParameters = (): typeof defaultParameters => {
			if (defaultParameters !== undefined) {
				return defaultParameters;
			}

			defaultParameters = $injector.has('entityLabels')
				? {
						entityLabels: $injector.get('entityLabels'),
				  }
				: null;

			return defaultParameters;
		};

		function isTranslateWithDeclaration(
			keyOrDeclaration: string | TranslateCombinedWithDeclaration
		): keyOrDeclaration is TranslateCombinedWithDeclaration {
			return !_.isString(keyOrDeclaration);
		}

		function resolveMessage(key: string, declaredText: string): DeclaredMessage {
			let message: DeclaredMessage;
			if (messagesWithOverride[key]) {
				message = getMessageInDefaultLanguage(key);
			} else if (service.isDefaultLanguage()) {
				message = declaredText || getMessageInDefaultLanguage(key);
			} else {
				message = service.getMessage(key);
				if (!message) {
					// If we didn't find the message in the server language, get it in default language
					message = declaredText || getMessageInDefaultLanguage(key);
				} else if (!_.isString(message)) {
					const errMsg = `Unsupported message type for key ${key}. Only string messages are supported.`;
					if (session.isDevExperimentEnvironment) {
						throw new Error(errMsg);
					} else {
						logger.warn(errMsg);
						message = declaredText || getMessageInDefaultLanguage(key);
					}
				}
			}

			return message;
		}

		function resolveParamsInjector(key: string, declaredText: string): MessageParamsInjector {
			let paramsInjector = paramsInjectorCache.get(key);
			if (paramsInjector) {
				return paramsInjector;
			}

			const message = resolveMessage(key, declaredText);

			if (!message.includes('{{')) {
				// bypass rest of logic if there is nothing to parse and inject into message
				// meaning there are no parameters and there is not entityLabel in message
				paramsInjector = () => message;
			} else {
				const interpolationFunction = $interpolate(message);

				paramsInjector = (params: ParamsSupplier) => {
					return interpolationFunction(params());
				};
			}

			paramsInjectorCache.set(key, paramsInjector);

			return paramsInjector;
		}

		const service: Translate = function translate(
			keyOrDeclaration: string | TranslateCombinedWithDeclaration,
			parameters?: Dictionary<StringLike>
		): string {
			let key: string;

			let declaredText: string;
			if (isTranslateWithDeclaration(keyOrDeclaration)) {
				key = keyOrDeclaration.key;
				declaredText = keyOrDeclaration.text;
				parameters = keyOrDeclaration.parameters;
			} else {
				key = keyOrDeclaration;
			}

			const paramsInjector = resolveParamsInjector(key, declaredText);

			const messageWithParams = paramsInjector(() =>
				Object.assign({}, getDefaultParameters(), parameters)
			);

			return messageWithParams;
		} as Translate;

		/**
		 * @ngdoc method
		 * @name platform-translation.translate#resourcesLoaded
		 * @methodOf platform-translation.translate
		 *
		 * @return {boolean} true if all the necessary resources have been loaded, false otherwise.
		 */
		service.resourcesLoaded = function resourcesLoaded(): boolean {
			_.forEach(successLogs, function (successLog) {
				logger.log(successLog);
			});
			if (_.isEmpty(failureLogs)) {
				isResourceLoaded = true;
				return true;
			} else {
				_.forEach(failureLogs, function (failureLog) {
					logger.error(failureLog);
				});
				return false;
			}
		};

		/**
		 * @ngdoc method
		 * @name platform-translation.translate#getMessage
		 * @methodOf platform-translation.translate
		 *
		 * @description
		 * Retrieves the localized message by the key. The key can be prefixed by a namespace followed by colon,
		 * e.g. qc:domain. If the namespace is not mentioned, the default namespace is assumed. This function
		 * is used by the {@link platform-translation.directive:translate translate} directive and shouldn't be used
		 * directly from the code.
		 * @param {String} key the message key
		 * @return {String} localized message or null in case the translation failed
		 */
		service.getMessage = function getMessage(key): DeclaredMessage {
			var result = i18n.t(key);

			if (validateResult(key, result)) {
				return result;
			}

			return null;
		};

		/**
		 * @ngdoc method
		 * @name platform-translation.translate#isDefaultLanguage
		 * @methodOf platform-translation.translate
		 *
		 * @description
		 * Returns true if the client is operating in the default (english) language, in which case we bypass
		 * loading messages from the resource files, but rather use inlined messages for directives or declared
		 * messages from the JS code.
		 * @return {Boolean} true if the client is operating in the default (english) language, false otherwise
		 */
		service.isDefaultLanguage = () => translateProvider.isDefaultLanguage();

		// For message file auto-generation purposes, don't use in production
		service.getDeclaredMessages = () => translateProvider.declaredMessages;

		service.overrideMessage = (translationKey: string, newValue: string) => {
			translateProvider.declaredMessages[translationKey] = newValue;
			messagesWithOverride[translationKey] = true;
		};

		return service;
	};
}

angular.module('platform-translation').provider('translate', TranslateProvider);

export let translate: Translate;
angular.module('platform-translation').run(function ($injector: angular.auto.IInjectorService) {
	translate = $injector.get('translate');
});
